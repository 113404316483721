<template>
    <v-card flat>
        <v-card-text class="pl-0 pr-0 pb-0">
            <v-row class="mt-n5">
                <v-col cols="3">
                    <v-select
                        class="text-h6"
                        single-line
                        :items="selSearchTypes"
                        v-model="search.search_type"
                        label="검색 유형을 선택해 주세요"
                        item-text="txt"
                        item-value="val"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-text-field
                        class="text-h6"
                        v-model="search.search_text"
                        ref="searchText"
                        @keyup.enter="doSearch(1)"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-btn
                        class="mx-2"
                        fab
                        color="#F4F6F8"
                        elevation="0"
                        @click="doSearch(1)"
                        style="float:left;"
                    >
                        <v-icon color="#A5ACB4">mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="align-center">
                <v-col cols="6">
                    <v-btn outlined class="text-h6" color="green" @click="showModal('addr_book_add')">
                        <v-icon left dark size="25">mdi-plus-circle</v-icon>
                        추가
                    </v-btn>
                    <v-btn outlined class="ml-3 text-h6" color="red" @click="removeAddrBook" :style="this.checkedAddrBookSeqnos.length == 0 ? {'opacity': 0.3} : {'opacity': 1}">
                        <v-icon left dark size="25">mdi-close-circle</v-icon>
                        삭제
                    </v-btn>
                </v-col>
                <v-col cols="6" class="pr-4 text-right" style="font-size:20px; color:#555555;">등록 건 수: {{ this.$options.filters.number(totalCount) }} 건</v-col>
            </v-row>
            <v-card flat>
                <v-card-text class="pl-0 pr-0 pb-0">
                    <v-simple-table class="border-table">
                        <thead>
                            <tr style="background-color:#F4F6F8; height:58px; color:#333333;">
                                <th style="width: 73px; height: 68px !important; max-height: 68px;" class="text-center text-h6">
                                    <v-checkbox
                                        v-model="checkAllFlag"
                                        v-if="addrBookList.length != 0"
                                        @change="checkAll"
                                        class="ml-2"
                                    ></v-checkbox>
                                    <span v-else class="text-center text-h6">-</span>
                                </th>
                                <th style="width: 20%" class="text-center text-h6">이름(표시명)</th>
                                <th style="width: 20%" class="text-center text-h6">연락처</th>
                                <th class="text-center text-h6">비고</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(addrBook, idx) in orderedList">
                                <tr style="height:58px;" :key="'cust_' + addrBook.addr_book_seqno + '_' + idx">
                                    <td class="text-center text-subtitle-1">
                                        <v-checkbox
                                            v-model="checkedAddrBookSeqnos"
                                            :value="addrBook.addr_book_seqno"
                                            class="ml-2"
                                        ></v-checkbox>
                                    </td>
                                    <td class="text-center text-subtitle-1" style="cursor: pointer;" @click="showModal('addr_book_modify', addrBook)">{{ addrBook.cust_name }}</td>
                                    <td class="text-center text-subtitle-1" style="cursor: pointer;" @click="showModal('addr_book_modify', addrBook)">{{ addrBook.cust_mdn | phoneNum }}</td>
                                    <td class="text-left text-subtitle-1">{{ addrBook.cust_desc }}</td>
                                </tr>
                            </template>
                            <tr v-if="!addrBookList || addrBookList.length == 0" style="height: 67px;">
                                <td class="text-center text-subtitle-1" colspan="4" style="height: 67px;">등록된 주소가 없습니다.</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
            </v-card>
            <v-row class="mt-4 align-center">
                <v-col cols="4"  style="height: 60px !important;">
                    <v-btn v-on:click="downloadExcel" :loading="excelLoading" color="success">DOWNLOAD</v-btn>
                    <v-btn class="ml-3" v-on:click="showModal('upload')" color="primary">UPLOAD</v-btn>
                </v-col>
                <v-col class="text-right"  style="height: 60px !important;">
                    <tile-paging :pageNo="search.page_no" :totalPageCount="totalPageCount" @goPage="doSearch"></tile-paging>
                </v-col>
            </v-row>

        </v-card-text>

        <v-dialog v-model="modal" persistent max-width="600">
            <v-flex v-if="dialogMode === 'upload'">
                <popup-upload v-on:cancel="closePopup"></popup-upload>
            </v-flex>
        </v-dialog>
    </v-card>
</template>

<script>
import {mapState} from 'vuex'
import addrBookApi from '@/api/addr_book'

export default {
    name: 'addr_book_list',
    components: {
        'tilePaging': () => import('@/components/common/tile_paging.vue'),
        'popupUpload': () => import('@/components/addr_book/popup_upload.vue'),
    },
    data: () => ({
        search: {
            search_text: '',
            search_type: 'CUST_MDN',
            page_no: 1,
            page_size: 10,
            order_col: '',
            order_dir: ''
        },
        selSearchTypes: [
            {'txt': '검색유형: 연락처', 'val': 'CUST_MDN'},
            {'txt': '검색유형: 이름', 'val': 'CUST_NAME'}
        ],
        checkedAddrBookSeqnos: [],
        checkAllFlag: false,
        modal: false,
        dialogMode: '',
        excelLoading: false
    }),
    computed: {
        ...mapState('addr_book', {
            curSearchParam: state => state.searchParam,
            addrBookList: state => state.addrBookList,
            totalCount: state => state.totalCount
        }),
        totalPageCount() {
            if (this.totalCount == 0) {
                return 0
            }
            let pageSize = (this.search && this.search.page_size) ? this.search.page_size : 10
            let cnt = Math.floor(this.totalCount / pageSize) + (((this.totalCount % pageSize) > 0) ? 1 : 0)
            return cnt
        },
        orderedList() {
            if (!this.addrBookList || this.addrBookList.length == 0) {
                return []
            }

            let ret = []
            this.addrBookList.forEach(item => {
                ret.push(item)
            })

            ret.sort((a, b) => {
                if (this.search.order_col == 'CUST_NAME' && this.search.order_dir == 'ASC') {
                    return a.cust_name < b.cust_name ? -1 : a.cust_name > b.cust_name ? 1 : 0
                } else if (this.search.order_col == 'CUST_NAME' && this.search.order_dir == 'DESC') {
                    return a.cust_name < b.cust_name ? 1 : a.cust_name > b.cust_name ? -1 : 0
                } else if (this.search.order_col == 'CUST_MDN' && this.search.order_dir == 'ASC') {
                    return a.cust_mdn < b.cust_mdn ? -1 : a.cust_mdn > b.cust_mdn ? 1 : 0
                } else if (this.search.order_col == 'CUST_MDN' && this.search.order_dir == 'DESC') {
                    return a.cust_mdn < b.cust_mdn ? 1 : a.cust_mdn > b.cust_mdn ? -1 : 0
                }
                return 1
            })

            return ret
        }
    },
    watch: {
        checkedAddrBookSeqnos() {
            if (this.checkAllFlag && this.addrBookList.length != this.checkedAddrBookSeqnos.length) {
                this.checkAllFlag = false
            } else if (!this.checkAllFlag && this.addrBookList.length == this.checkedAddrBookSeqnos.length) {
                this.checkAllFlag = true
            }
        }
    },
    created() {
        this.doSearch()
    },
    methods: {
        doSearch: function (pageNo) {
            if (pageNo) {
                this.search.page_no = pageNo
            }

            if (this.search.search_type == 'CUST_MDN' && this.search.search_text.length != 0) {
                let replaceText = this.search.search_text.replaceAll(/[^0-9]/g, '')
                this.search.search_text = replaceText
                this.$refs.searchText = replaceText

                if (replaceText.length == 0) {
                    this.$store.dispatch('openAlert', {
                        'message': '연락처를 입력해 주세요',
                        'sub_message': '연락처는 숫자만 입력가능합니다'
                    })
                    return
                }
            }

            this.$store.dispatch('addr_book/getList', {
                param: {
                    search_type: this.search.search_type,
                    search_text: this.search.search_text,
                    page_no: this.search.page_no,
                    page_size: this.search.page_size
                },
                scb: () => {

                }, fcb: err => {
                    if (this.$noSessionProc(err)) {
                        console.log('authentication check failed')
                    }
                }
            })
        },
        setOrder(col) {
            this.search.order_dir = (this.search.order_col != col || this.search.order_dir == 'ASC') ? 'DESC' : 'ASC'
            this.search.order_col = col
            // this.doSearch(1)
        },
        checkAll() {
            if (this.checkAllFlag && this.addrBookList.length != this.checkedAddrBookSeqnos.length) {
                this.checkedAddrBookSeqnos = []
                this.addrBookList.forEach(item => {
                    this.checkedAddrBookSeqnos.push(item.addr_book_seqno)
                })
            } else if (!this.checkAllFlag && this.checkedAddrBookSeqnos.length != 0) {
                this.checkedAddrBookSeqnos = []
            }
        },
        showModal(dialogMode, item) {

            if (dialogMode == 'upload') {
                this.modal = true
                this.dialogMode = dialogMode
            } else {
                let addrBook = {}
                if (dialogMode == 'addr_book_add') {
                    addrBook.pageMode = 'add'
                } else {
                    addrBook.addr_book_seqno = item.addr_book_seqno
                    addrBook.cust_name = item.cust_name
                    addrBook.cust_mdn = item.cust_mdn
                    addrBook.cust_desc = item.cust_desc
                    addrBook.pageMode = 'modify'
                }

                this.$store.dispatch('openAddrBook', {
                    'addrBook': addrBook,
                    'okCb': (va) => {
                        va.doSearch(1)
                    },
                    'va': this
                })
            }

            /*if (this.modal) {
                this.closePopup()
            }

            this.modal = true
            this.dialogMode = dialogMode*/
        },
        removeAddrBook() {
            if (this.checkedAddrBookSeqnos.length == 0) {
                return
            }
            addrBookApi.deleteAddrBook(
                {
                    'addr_book_seqnos': this.checkedAddrBookSeqnos
                },
                (va) => {
                    va.checkedAddrBookSeqnos.splice(0, va.checkedAddrBookSeqnos.length)
                    va.doSearch(1)
                },
                (err, va) => {
                    va.$store.dispatch('openAlert', {
                        'message': '삭제 처리 중 오류가 발생하였습니다'
                    })
                },
                this
            )
        },
        submitAdd() {
            this.$store.commit('addr_book/setAddrBook', {})
            this.doSearch(1)
        },
        closePopup(isReload) {
            if (this.dialogMode == 'addr') {
                this.$store.commit('addr_book/setAddrBook', {})
            } else if (this.dialogMode == 'upload') {
                this.$store.commit('addr_book/setAttachment', {})
            }

            this.modal = false
            this.dialogMode = ''

            if (isReload) {
                this.doSearch(1)
            }
        },
        downloadExcel() {
            let va = this
            va.$store.dispatch('openConfirm', {
                message: '주소록 목록을 엑셀 파일로 다운로드 하시겠습니까?',
                va: va,
                okCb: function (va) {
                    va.excelLoading = true
                    addrBookApi.downloadExcel(
                        va.search,
                        (va) => {
                            va.excelLoading = false
                            va.$store.dispatch('openAlert', {message: '엑셀 파일이 다운로드 되었습니다',})
                        }, (err, va) => {
                            va.excelLoading = false
                            va.$store.dispatch('openAlert', {message: '엑셀 파일이 다운로드를 실패 했습니다',})
                        }, va
                    )
                }
            })
        }
    }
}
</script>